import {createAction, props} from '@ngrx/store';

export const loadPromo = createAction(
  '[Promo] Load promo',
  props<{ promo: any[] }>()
);

export const addPromo = createAction(
  '[Promo] Add promo',
  props<{ promo: {} }>()
);

export const updatePromo = createAction(
  '[User] Update promo',
  props<{ promo: string | number, update: {} | any }>()
);

export const deletePromo = createAction(
  '[Promo] Delete promo',
  props<{ promo: any[] }>()
);
