import { Action, createReducer, on } from '@ngrx/store';
import Product from '../../shared/models/inventory/product';
import { loadProducts, addProduct, deleteProduct, removeAllProducts, updateProduct } from 'src/app/actions/inventory/product.action';

export const productFeatureKey = 'product';

export const initialState: Product[] = [];

const productReducer = createReducer(
  initialState,
  on(loadProducts, (state, { product }) => [...state, ...product.filter(p => !state.find(s => s._id === p._id))]),
  on(removeAllProducts, (state, { }) => []),
  on(addProduct, (state, { product }) => [product, ...state]),
  on(deleteProduct, (state, { products }) => state.filter(item => !products.includes((item._id)))),
  on(updateProduct, (state, { product, update }) => state.map(item => {
    if (item._id === product) {
      return { ...item, ...update };
    } else {
      return item;
    }
  })),
);

export function reducer(state: Product[], action: Action) {
  return productReducer(state, action);
}
