import {Action, createReducer, on} from '@ngrx/store';
import {addCatalogue, deleteCatalogue, loadCatalogues, updateCatalogue} from '../../actions/inventory/catalogue.action';
import Catalogue from '../../shared/models/inventory/catalogue';

export const catalogueFeatureKey = 'catalogue';

export const initialState: Catalogue[] = [];

const catalogueReducer = createReducer(
  initialState,
  on(loadCatalogues, (state, {catalogues}) => catalogues),
  on(addCatalogue, (state, {catalogue}) => [catalogue, ...state]),
  on(deleteCatalogue, (state, {catalogue}) => state.filter(item => item._id !== catalogue)),
  on(updateCatalogue, (state, {catalogue, update}) => state.map(item => {
    if (item.id === catalogue) {
      return {...item, ...update};
    } else {
      return item;
    }
  })),
);

export function reducer(state: Catalogue[], action: Action) {
  return catalogueReducer(state, action);
}
