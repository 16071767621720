import { Action, createReducer, on } from '@ngrx/store';
import { loadOrders, removeAllOrders, updateOrder } from '../../actions/inventory/order.action';


export const initialState: any[] = [];

const orderReducer = createReducer(initialState,
  on(loadOrders, (state, { orders }) => [...state, ...orders.filter(p => !state.find(s => s._id === p._id))]),
  on(removeAllOrders, (state, { }) => []),
  on(updateOrder, (state, { order, update }) => state.map(item => {
    if (item._id === order) {
      return { ...item, ...update };
    } else {
      return item;
    }
  })),
);


export function reducer(state: any[], action: Action) {
  return orderReducer(state, action);
}
