import { Action, createReducer, on } from '@ngrx/store';
import { addAddOn, deleteAddOn, loadAddOns, removeAllAddOns, updateAddOn } from '../../actions/inventory/addon.action';
import AddOn from '../../shared/models/inventory/addon';

export const addonFeatureKey = 'addon';

export const initialState: AddOn[] = [];

const addonReducer = createReducer(
    initialState,
    on(loadAddOns, (state, { addons }) => addons),
    on(addAddOn, (state, { addon }) => [addon, ...state]),
    on(removeAllAddOns, (state, { }) => []),
    on(deleteAddOn, (state, { addon }) => state.filter(item => !addon.includes(item._id))),
    on(updateAddOn, (state, { addon, update }) => state.map(item => {
        if (item._id === addon) {
            return { ...item, ...update };
        } else {
            return item;
        }
    })),
);

export function reducer(state: AddOn[], action: Action) {
    return addonReducer(state, action);
}
