import {createAction, props} from '@ngrx/store';

export const loadProducts = createAction(
  '[Product] Load Products',
  props<{ product: any[] }>()
);
export const addProduct = createAction(
  '[Product] Add Product',
  props<{ product: {} | any }>()
);
export const deleteProduct = createAction(
  '[Product] Delete Product',
  props<{ products: any[] }>()
);
export const updateProduct = createAction(
  '[Product] Update Product',
  props<{ product: number | string, update: {} | any }>()
);
export const removeAllProducts = createAction(
  '[Product] Remove All Products',
  props<any>()
);
