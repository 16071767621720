import {createAction, props} from '@ngrx/store';

export const loadProductCategory = createAction(
  '[ProductCategory] Load ProductCategory',
  props<{ productcategory: any[] }>()
);
export const addProductCategory = createAction(
  '[ProductCategory] Add ProductCategory',
  props<{ productcategory: {} | any }>()
);
export const deleteProductCategory = createAction(
  '[ProductCategory] Delete ProductCategory',
  props<{ productcategory: string[] }>()
);
export const updateProductCategory = createAction(
  '[ProductCategory] Update ProductCategory',
  props<{ productcategory: number | string, update: {} | any }>()
);

