import { Action, createReducer, on } from '@ngrx/store';
import { loadConfigurations } from './../actions/configuration.action';


export const initialState: any = {};

const configurationReducer = createReducer(initialState,
  on(loadConfigurations, (state, { configurations }) => configurations)

);



export function reducer(state: any, action: Action) {
  return configurationReducer(state, action);
}
