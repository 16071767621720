import {createAction, props} from '@ngrx/store';

export const loadUsers = createAction(
  '[User] Load users',
  props<{ users: any[] }>()
);

export const updateUser = createAction(
  '[User] Update user',
  props<{ user: string | number, update: {} | any }>()
);

export const deleteUser = createAction(
  '[User] Delete user',
  props<{ user: any[] }>()
);
