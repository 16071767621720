import { NetworkService } from './../../../services/network/network.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {
  GET_ANALYTICS = '/inventory/v2/analytics/get-orders';
  EXPORT_ANALYTICS = '/inventory/v2/analytics/export';
  DOWNLOAD_REPORT = '/inventory/analytics/download?fileName=';
  GET_TRANSACTION = '/payment/transaction/organisation';
  GET_VISUAL = '/inventory/v2/analytics/orders/count';
  // ORDER_GRAPH_DATA='/inventory/v2/analytics/order-analytics?period=yearly&tag=totalOrders';
  // REVENUE_GRAPH_DATA='/inventory/v2/analytics/revenue-analytics?period=monthly';
  ORDER_GRAPH_DATA = '/inventory/v2/analytics/order-analytics';
  REVENUE_GRAPH_DATA = '/inventory/v2/analytics/revenue-analytics';

  constructor(
    private networkService: NetworkService,
    private store: Store<any>
  ) { }

  /**************** anaylytics ************/
  async getAnalytics(data) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.GET_ANALYTICS, data, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async exportAnalytics(data) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.EXPORT_ANALYTICS, data, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async downloadReport(fileName: string) {
    return new Promise((resolve, reject) => {
      this.networkService.download(this.DOWNLOAD_REPORT + fileName, undefined, 'GET', true, fileName)
        .then(resolve)
        .catch(reject);
    });
  }

  async getTransactions() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.GET_TRANSACTION, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }
  async getVisualData() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.GET_VISUAL, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }
  // async getOrderGraphData() {
  //   return new Promise((resolve, reject) => {
  //     this.networkService.httpRequest(this.ORDER_GRAPH_DATA, null, 'GET', true)
  //       .then(resolve)
  //       .catch(reject);
  //   });
  // }

  // async getRevenueGraphData() {
  //   return new Promise((resolve, reject) => {
  //     this.networkService.httpRequest(this.REVENUE_GRAPH_DATA, null, 'GET', true)
  //       .then(resolve)
  //       .catch(reject);
  //   });
  // }
  async getOrderGraphData(period: string, selectedOrderTag: string) {
    const url = `${this.ORDER_GRAPH_DATA}?period=${period}&tag=${selectedOrderTag}`;
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(url, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async getRevenueGraphData(period: string) {
    const url = `${this.REVENUE_GRAPH_DATA}?period=${period}`;
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(url, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }

}
