import { Action, createReducer, on } from '@ngrx/store';
import { loadPermissions, addPermission, updatePermission, deletePermission } from '../../actions/setting/permission.action';


export const initialState: any[] = [];

const permissionReducer = createReducer(initialState,
  on(loadPermissions, (state, { permissions }) => permissions),
  on(addPermission, (state, { permission }) => [...state, ...permission]),
  on(deletePermission, (state, { permissions }) => permissions.filter(item => !permissions.includes(item._id))),
  on(updatePermission, (state, { permission, update }) => state.map(item => {
    if (item._id === permission) {
      return { ...item, ...update };
    } else {
      return item;
    }
  }))
);

export function reducer(state: any[], action: Action) {
  return permissionReducer(state, action);
}
