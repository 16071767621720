import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadUsers } from './../../actions/user.action';
import { loadDrivers } from './../../actions/driver.action';
import { loadShops } from './../../actions/shop.action';
import { loadProfile } from '../../actions/profile.action';
import { loadConfigurations } from '../../actions/configuration.action';
import { CommonService } from '../common/common.service';
import { NetworkService } from '../network/network.service';
import { Router } from '@angular/router';
import { loadCatalogues } from '../../actions/inventory/catalogue.action';
import { environment } from '../../../environments/environment';
import { loadAddOns } from 'src/app/actions/inventory/addon.action';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  GET_USERS = '/authentication/user/list';
  GET_DRIVERS = '/authentication/driver/list';
  GET_SHOPS = '/authentication/shop/list';
  UPDATE_TENANT = '/authentication/tenant/last-login';
  GET_CONFIGURATION = '/configuration/configuration';
  GET_CATALOGUE = '/inventory/public/catalogues/super-admin';
  SOCKET_ACKNOWLEDGEMENT = '/web-socket/publish/';
  GET_CMS = '/cms/public/';
  CMS = '/cms/content';
  GET_ITEM_ADDON = '/inventory/addon-item/list';
  GET_PROFILE = atob(localStorage.getItem('type')) === 'TENANT' ? '/authentication/tenant/me' : '/authentication/shop/me';
  private ADD_IMAGE = '/media/image';

  ADD_EDIT_CATALOGUES = '/inventory/catalogues/catalogue';
  // ADD_CATALOGUES='/inventory/catalogues/catalogue';
  ADD_CATALOG = '/inventory/catalogues';
  // GET_ALL_CATALOGUES='inventory/public/catalogues'

  constructor(
    private cos: CommonService,
    private network: NetworkService,
    private router: Router,
    private store: Store<any>
  ) { }

  getUsers() {
    this.network.httpRequest(this.GET_USERS)
      .then((resu: any) => {
        if (resu.status) {
          this.store.dispatch(loadUsers({ users: resu.data }));
        }
      })
      .catch(err => console.log(err));
  }

  getDrivers() {
    this.network.httpRequest(this.GET_DRIVERS)
      .then((resu: any) => {
        if (resu.status) {
          this.store.dispatch(loadDrivers({ drivers: resu.data }));
        }
      })
      .catch(err => console.log(err));
  }

  getShops() {
    this.network.httpRequest(this.GET_SHOPS)
      .then((resu: any) => {
        if (resu.status) {
          this.store.dispatch(loadShops({ shops: resu.data }));
        }
      })
      .catch(err => console.log(err));
  }

  getProfile() {
    this.network.httpRequest(this.GET_PROFILE)
      .then((resu: any) => {
        if (resu.status) {
          this.store.dispatch(loadProfile({ profile: resu.data }));
        }
      })
      .catch(err => this.cos.openSnackBar({ message: err.message }));
  }

  getConfiguration() {
    this.network.httpRequest(this.GET_CONFIGURATION)
      .then((resu: any) => {
        if (resu.status) {
          this.store.dispatch(loadConfigurations({ configurations: resu.data }));
          const root = document.documentElement;

          root.style.setProperty('--theme-color', resu.data.platForm.general.primaryColor);
          root.style.setProperty('--sec-color', resu.data.platForm.general.secondaryColor);
        }
      })
      .catch(err => this.cos.openSnackBar({ message: err.message }));
  }

  getCatalogues() {
    this.network.httpRequest(this.GET_CATALOGUE)
      .then((resu: any) => {
        if (resu.status) {
          this.store.dispatch(loadCatalogues({ catalogues: resu.data }));
        }
      })
      .catch(err => this.cos.openSnackBar({ message: err.message }));
  }

  getAddOnItemList() {
    this.network.httpRequest(this.GET_ITEM_ADDON)
      .then((resu: any) => {
        if (resu.status) {
          this.store.dispatch(loadAddOns({ addons: resu.data }));
        }
      })
      .catch(err => this.cos.openSnackBar({ message: err.message }));
  }

  /**
   * GET LOCATION FROM IP ADDRESS
   */

  getAddressFromIp() {
    this.network.openHttpRequest(environment.IP_TO_LOCATION)
      .then((resu: any) => {
        localStorage.setItem('signUpLocation', JSON.stringify(resu));
      })
      .catch(err => localStorage.setItem('signUpLocation', JSON.stringify({})));
  }

  updateLoginDeails() {
    this.network.openHttpRequest(environment.IP_TO_LOCATION)
      .then((resu: any) => {
        this.network.httpRequest(this.UPDATE_TENANT, { signUpLocation: resu, lastLogin: new Date() }, 'PUT', true)
          .catch(err => { throw err; });
      }).catch(err => console.log(err));
  }

  getCMS(tenant: string) {
    return new Promise((resolve, reject) => {
      this.network.httpRequest(this.GET_CMS + '?_tenant=' + tenant)
        .then(resolve)
        .catch(reject);
    });
  }

  socketAcknowledgement(id) {
    return new Promise((resolve, reject) => {
      this.network.httpRequest(this.SOCKET_ACKNOWLEDGEMENT + id, null, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

  updateCMS(data: any) {
    return new Promise((resolve, reject) => {
      this.network.httpRequest(this.CMS, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }





  async addImage(data) {
    return new Promise((resolve, reject) => {
      this.network.httpFormRequest(this.ADD_IMAGE, data, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }



  getCatalogs() {
    return new Promise((resolve, reject) => {
      this.network.httpRequest(this.ADD_CATALOG, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }


  // getCatalog() {
  //   return new Promise((resolve, reject) => {
  //     this.network.httpRequest(this.GET_ALL_CATALOGUES, null, 'GET', true)
  //       .then(resolve)
  //       .catch(reject);
  //   });
  // }
  editCatalogs(data: any, id: string) {
    return new Promise((resolve, reject) => {
      this.network.httpRequest(this.ADD_EDIT_CATALOGUES + '/' + id, data, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }

  addCatalogs(data: any) {
    return new Promise((resolve, reject) => {
      this.network.httpRequest(this.ADD_EDIT_CATALOGUES, data, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }
}

