import { Action, createReducer, on } from '@ngrx/store';
import { loadProfile, updateProfile } from './../actions/profile.action';


export const initialState: any = {};

const profileReducer = createReducer(initialState,
  on(loadProfile, (state, { profile }) => profile),
  on(updateProfile, (state, { profile, update }) => update)
);

export function reducer(state: any = {}, action: Action) {
  return profileReducer(state, action);
}
