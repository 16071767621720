import { createAction, props } from '@ngrx/store';

export const loadAddOns = createAction(
    '[AddOn] Load Addon',
    props<{ addons: any[] }>()
);
export const addAddOn = createAction(
    '[AddOn] Add Addon',
    props<{ addon: {} | any }>()
);
export const deleteAddOn = createAction(
    '[AddOn] Delete Addon',
    props<{ addon: any }>()
);
export const updateAddOn = createAction(
    '[AddOn] Update Addon',
    props<{ addon: number | string, update: {} | any }>()
);

export const removeAllAddOns = createAction(
    '[AddOn] Remove All AddOns',
    props<any>()
  );
