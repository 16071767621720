import { Action, createReducer, on } from '@ngrx/store';
import { addLocation, deleteLocation, loadLocations, updateLocation } from '../../actions/inventory/location.action';

export const locationFeatureKey = 'location';

export const initialState: Location[] = [];

const locationReducer = createReducer(
  initialState,
  on(loadLocations, (state, { locations }) => locations),
  on(addLocation, (state, { location }) => [location, ...state]),
  on(deleteLocation, (state, { locations }) => state.filter(item => !locations.includes((item._id)))),
  on(updateLocation, (state, { location, update }) => state.map(item => {
    if (item._id === location) {
      return { ...item, ...update };
    } else {
      return item;
    }
  })),
);

export function reducer(state: Location[], action: Action) {
  return locationReducer(state, action);
}
