
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { UnAuthGuard } from './guards/un-auth.guard';
import { ElementsComponent } from './shared/layout/elements/elements.component';
import { SidebarComponent } from './shared/layout/sidebar/sidebar.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/dashboard', pathMatch: 'full',
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/auth.module')
      .then(m => m.AuthModule)
  },
  {
    path: 'get-started',
    loadChildren: () => import('./modules/onboarding/onboarding.module')
      .then(m => m.OnboardingModule)
  },
  {
    path: 'dashboard',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module')
      .then(m => m.DashboardModule)
  },

  {
    path: 'users',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/users/users.module')
      .then(m => m.UsersModule)
  },

  {
    path: 'inventory',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/inventory/inventory.module')
      .then(m => m.InventoryModule)
  },

  {
    path: 'deliveries',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/deliveries/deliveries.module')
      .then(m => m.DeliveriesModule)
  },

  {
    path: 'marketing',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/marketing/marketing.module')
      .then(m => m.MarketingModule)
  },

  {
    path: 'analytics',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/analytics/analytics.module')
      .then(m => m.AnalyticsModule)
  },

  {
    path: 'setting',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/setting/setting.module')
      .then(m => m.SettingModule)
  },

  {
    path: 'support',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/support/support.module')
      .then(m => m.SupportModule)
  },
  {
    path: 'leads',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/leads/leads.module')
      .then(m => m.LeadsModule)
  },
  {
    path: 'cms',
    canLoad: [UnAuthGuard],
    loadChildren: () => import('./modules/cms/cms.module')
      .then(m => m.CmsModule)
  },
  {
    path: 'elements',
    canLoad: [UnAuthGuard],
    component: ElementsComponent
  },
  {
    path: '**', redirectTo: '/inventory/location', pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
