import {createAction, props} from '@ngrx/store';

export const loadCatalogues = createAction(
  '[Catalogue] Load Catalogues',
  props<{ catalogues: any[] }>()
);
export const addCatalogue = createAction(
  '[Catalogue] Add Catalogue',
  props<{ catalogue: {} | any }>()
);
export const deleteCatalogue = createAction(
  '[Catalogue] Delete Catalogue',
  props<{ catalogue: number | string }>()
);
export const updateCatalogue = createAction(
  '[Catalogue] Update Catalogue',
  props<{ catalogue: number | string, update: {} | any }>()
);
