import {createAction, props} from '@ngrx/store';

export const loadLocations = createAction(
  '[Location] Load Locations',
  props<{ locations: any[] }>()
);
export const addLocation = createAction(
  '[Location] Add Location',
  props<{ location: {} | any }>()
);
export const deleteLocation = createAction(
  '[Location] Delete Location',
  props<{ locations: any[] }>()
);
export const updateLocation = createAction(
  '[Location] Update Location',
  props<{ location: number | string, update: {} | any }>()
);
