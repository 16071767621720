import { NetworkService } from './../../../services/network/network.service';
import { CommonService } from './../../../services/common/common.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  newOrderSubscriber = new Subject();
  orderExpiredSubscriber = new Subject();
  assignedOrderSubscriber = new Subject();

  private SHOP_VIEW_LIVE_ORDER = '/inventory/v2/orders/live/shop-view';
  private PRODUCT_VIEW_LIVE_ORDER = '/inventory/v2/orders/live/product-view';
  private PRODUCT_VIEW_LIVE_ORDER_WTH_SKU_PRICE_MANAGEMENT = '/inventory/orders/live/product-view/sku-price-management';
  private PROCESSING_ORDERS = '/inventory/v2/dashboard/orders/processing';
  private COMPLETED_ORDERS = '/inventory/v2/dashboard/orders/completed';
  private ACCEPT = '/inventory/orders/shop/accept';
  private REJECT = '/inventory/orders/shop/reject';
  private DELIVERABLE = '/inventory/orders/shop/deliverable';
  private INPROGRESS = '/inventory/orders/shop/inprogress';
  private SUGGEST = '/inventory/products/suggest';
  view = 'SHOP';

  constructor(
    private cos: CommonService,
    private networkService: NetworkService
  ) {
  }

  async getLiveOrders(view: any, skuAndPriceManagement: boolean) {
    return new Promise((resolve, reject) => {
      if (view === 'SHOP') {
        this.networkService.httpRequest(this.SHOP_VIEW_LIVE_ORDER, null, 'GET', true)
          .then(resolve)
          .catch(reject);
      } else if (view === 'PRODUCT' && !skuAndPriceManagement) {
        this.networkService.httpRequest(this.PRODUCT_VIEW_LIVE_ORDER, null, 'GET', true)
          .then(resolve)
          .catch(reject);
      } else if (view === 'PRODUCT' && skuAndPriceManagement) {
        this.networkService.httpRequest(this.PRODUCT_VIEW_LIVE_ORDER_WTH_SKU_PRICE_MANAGEMENT, null, 'GET', true)
          .then(resolve)
          .catch(reject);
      }
    });
  }
  async getSuggestionList(query: string) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.SUGGEST + query, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async getProcessingOrders(data: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.PROCESSING_ORDERS, data, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async getCompletedOrders(data: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.COMPLETED_ORDERS, data, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async acceptOrder(data: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.ACCEPT, data, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async deliverableOrder(data: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.DELIVERABLE, data, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async inProgressOrder(data: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.INPROGRESS, data, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async rejectOrder(data: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.REJECT, data, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }


}
