import {createAction, props} from '@ngrx/store';

export const loadPermissions = createAction(
  '[Permission] Load permissions',
  props<{ permissions: any[] }>()
);

export const addPermission = createAction(
  '[Permission] Add permission',
  props<{ permission: {} | any }>()
);

export const updatePermission = createAction(
  '[Permission] Update permission',
  props<{ permission: string | number, update: {} | any }>()
);

export const deletePermission = createAction(
  '[Permission] Delete permissions',
  props<{ permissions: any[] }>()
);
