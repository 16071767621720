import { ToastrService } from "ngx-toastr";
import { NetworkService } from "./../network/network.service";
import { environment } from "./../../../environments/environment";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { BehaviorSubject } from "rxjs";
import {AddressModel} from "../../models/Shop";
// import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: "root",
})
export class CommonService {
  snakBarConfig: any = {
    message: "Hi This is message",
    autoHide: 2000,
    verticalPosition: "top",
    horizontalPosition: "right",
    actionButtonLabel: "Retry",
  };
  socketInstance = new BehaviorSubject(null);
  setAutoHide = true;
  action = false;
  constructor(
    public router: Router,
    private snackBar: MatSnackBar,
    private networkService: NetworkService,
    private toast: ToastrService
  ) {}

  uploadImage = (file: any, name?: string) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file, file.name);

      this.networkService
        .httpFormRequest("/media/image", formData, "POST", true)
        .then((response: any) => resolve(response.data))
        .catch((err) => reject(err?.message));
    })

  uploadFile = (file: any, name?: string) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file, name ? name : file.name);

      this.networkService
        .httpFormRequest("/media/file", formData, "POST", true)
        .then((response: any) => resolve(response.data))
        .catch((err) => reject(err?.message));
    })

  getFormattedAddress(address: Address) {
    address = JSON.parse(JSON.stringify(address));
    const formatted_address = address.formatted_address;
    const geo = [address.geometry.location.lng, address.geometry.location.lat];
    const country = address.address_components.find((x) =>
      x.types.includes("country")
    );
    const locality = address.address_components.find((x) =>
      x.types.includes("locality")
    );
    const postalCode = address.address_components.find((x) =>
      x.types.includes("postal_code")
    );
    const streetAddress = address.address_components.find((x) =>
      x.types.includes("street_address")
    );
    const administrativeAreaLevel1 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_1")
    );
    const administrativeAreaLevel2 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_2")
    );
    const administrativeAreaLevel3 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_3")
    );
    const administrativeAreaLevel4 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_4")
    );
    const administrativeAreaLevel5 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_5")
    );
    const sublocalityLevel1 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_1")
    );
    const sublocalityLevel2 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_2")
    );
    const sublocalityLevel3 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_3")
    );
    const sublocalityLevel4 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_4")
    );
    const sublocalityLevel5 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_5")
    );

    return {
      country: country ? country.long_name : "",
      locality: locality ? locality.long_name : "",
      postalCode: postalCode ? postalCode.long_name : "",
      streetAddress: formatted_address ? formatted_address : "",
      formattedAddress: formatted_address ? formatted_address : "",
      region:
        (administrativeAreaLevel1 ? administrativeAreaLevel1.long_name : "") +
        (administrativeAreaLevel2 ? administrativeAreaLevel2.long_name : "") +
        (administrativeAreaLevel3 ? administrativeAreaLevel3.long_name : "") +
        (administrativeAreaLevel4 ? administrativeAreaLevel4.long_name : "") +
        (administrativeAreaLevel5 ? administrativeAreaLevel5.long_name : ""),
      subLocality:
        (sublocalityLevel1 ? sublocalityLevel1.long_name : "") +
        (sublocalityLevel2 ? sublocalityLevel2.long_name : "") +
        (sublocalityLevel3 ? sublocalityLevel3.long_name : "") +
        (sublocalityLevel4 ? sublocalityLevel4.long_name : "") +
        (sublocalityLevel5 ? sublocalityLevel5.long_name : ""),
      geo,
    };
  }

  getFormattedAddressModel(address: Address): AddressModel {
    address = JSON.parse(JSON.stringify(address));
    const formatted_address = address.formatted_address;

    // Get Location
    const location = JSON.parse(JSON.stringify(address.geometry.location));
    const geo: [number, number] = [location.lng, location.lat];

    const country = address.address_components.find((x) =>
      x.types.includes("country")
    );
    const locality = address.address_components.find((x) =>
      x.types.includes("locality")
    );
    const postalCode = address.address_components.find((x) =>
      x.types.includes("postal_code")
    );
    const streetAddress = address.address_components.find((x) =>
      x.types.includes("street_address")
    );
    const administrativeAreaLevel1 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_1")
    );
    const administrativeAreaLevel2 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_2")
    );
    const administrativeAreaLevel3 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_3")
    );
    const administrativeAreaLevel4 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_4")
    );
    const administrativeAreaLevel5 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_5")
    );
    const sublocalityLevel1 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_1")
    );
    const sublocalityLevel2 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_2")
    );
    const sublocalityLevel3 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_3")
    );
    const sublocalityLevel4 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_4")
    );
    const sublocalityLevel5 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_5")
    );
    const landmark = address.address_components.find((x) =>
      x.types.includes("landmark")
    );

    return {
      country: country ? country.long_name : "",
      locality: locality ? locality.long_name : "",
      postalCode: postalCode ? postalCode.long_name : "",
      streetAddress: formatted_address ? formatted_address : "",
      completeAddress: formatted_address ? formatted_address : "",
      landmark: landmark?.long_name ?? "",
      region:
        (administrativeAreaLevel1 ? administrativeAreaLevel1.long_name : "") +
        (administrativeAreaLevel2 ? administrativeAreaLevel2.long_name : "") +
        (administrativeAreaLevel3 ? administrativeAreaLevel3.long_name : "") +
        (administrativeAreaLevel4 ? administrativeAreaLevel4.long_name : "") +
        (administrativeAreaLevel5 ? administrativeAreaLevel5.long_name : ""),
      subLocality:
        (sublocalityLevel1 ? sublocalityLevel1.long_name : "") +
        (sublocalityLevel2 ? sublocalityLevel2.long_name : "") +
        (sublocalityLevel3 ? sublocalityLevel3.long_name : "") +
        (sublocalityLevel4 ? sublocalityLevel4.long_name : "") +
        (sublocalityLevel5 ? sublocalityLevel5.long_name : ""),
      geo
    };
  }

  getLocation(address: Address) {
    address = JSON.parse(JSON.stringify(address));
    const formatted_address = address.formatted_address;
    const name = address.name;
    const geo = [address.geometry.location.lng, address.geometry.location.lat];
    const country = address.address_components.find((x) =>
      x.types.includes("country")
    );
    const locality = address.address_components.find((x) =>
      x.types.includes("locality")
    );
    const postalCode = address.address_components.find((x) =>
      x.types.includes("postal_code")
    );
    const streetAddress = address.address_components.find((x) =>
      x.types.includes("street_address")
    );
    const administrativeAreaLevel1 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_1")
    );
    const administrativeAreaLevel2 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_2")
    );
    const administrativeAreaLevel3 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_3")
    );
    const administrativeAreaLevel4 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_4")
    );
    const administrativeAreaLevel5 = address.address_components.find((x) =>
      x.types.includes("administrative_area_level_5")
    );
    const sublocalityLevel1 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_1")
    );
    const sublocalityLevel2 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_2")
    );
    const sublocalityLevel3 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_3")
    );
    const sublocalityLevel4 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_4")
    );
    const sublocalityLevel5 = address.address_components.find((x) =>
      x.types.includes("sublocality_level_5")
    );

    return {
      name,
      country: country ? country.long_name : "",
      administrativeAreaLevel1: administrativeAreaLevel1
        ? administrativeAreaLevel1.long_name
        : "",
      administrativeAreaLevel2: administrativeAreaLevel1
        ? administrativeAreaLevel1.long_name
        : "",
      geo,
    };
  }

  openSnackBar(snackbar: any = null) {
    snackbar = { ...this.snakBarConfig, ...snackbar };
    const config = new MatSnackBarConfig();
    config.verticalPosition = snackbar.verticalPosition;
    config.horizontalPosition = snackbar.horizontalPosition;
    // config.duration = this.setAutoHide ? snackbar.autoHide : 0;
    config.duration = 5000;
    config.panelClass = ["snackBar-color"]; // scss define in style.css
    this.snackBar.open(
      snackbar.message,
      this.action ? snackbar.actionButtonLabel : undefined,
      config
    );
  }

  copyMessage(val) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  getStatusText(status: string) {
    switch (status) {
      case "PLACED": {
        return "Order placed";
      }
      case "ASSIGNING_SHOP": {
        return "Assigning shop";
      }
      case "ASSIGNED": {
        return "Shop assigned";
      }
      case "ACCEPTED": {
        return "Order accepted";
      }
      case "REJECTED": {
        return "Order rejected";
      }
      case "USER_SUGGESTED": {
        return "Order suggested by customer";
      }
      case "SHOP_SUGGESTED": {
        return "Order suggested by shop";
      }
      case "CANCELLED_BY_USER": {
        return "Order cancelled";
      }
      case "NO_SHOP_FOUND": {
        return "No shop found";
      }
      case "DELIVERABLE": {
        return "Order ready for delivery";
      }
      case "SHIPPING": {
        return "Order shipping";
      }
      case "ARRIVED_AT_SHOP": {
        return "Agent arrived at shop";
      }
      case "ORDER_PICKEDUP": {
        return "Order picked up by agent";
      }
      case "REACHED": {
        return "Agent reached destination";
      }
      case "COMPLETED": {
        return "Order completed";
      }
      case "NO_RIDER_FOUND": {
        return "No agent found";
      }
      case "RIDER_ASSIGNED": {
        return "Agent assigned";
      }
      case "REFUND_REQUESTED": {
        return "Refund requested";
      }
      case "REFUND_FAILED": {
        return "Refund failed";
      }
      case "REFUND_INITIATED": {
        return "Refund initiated";
      }
      case "REFUNDED": {
        return "Refunded";
      }
      default: {
        return "";
      }
    }
  }

  // public startLoader(): void{
  //   this.ngxService.start()
  // }

  // public stopLoader(): void{
  //   this.ngxService.stopAll()
  // }

  /**
   * Return alphabetically sorted list of currencies
   */
  sortCurrencyList(currencies) {
    return currencies.sort((a, b) => {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
  }
}
