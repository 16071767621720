<ngx-ui-loader></ngx-ui-loader>
<router-outlet></router-outlet>
<div _ngcontent-opc-c0="" class="help" id="help">
  <!-- <div _ngcontent-opc-c0="" class="icon on-close"><img _ngcontent-opc-c0="" alt=""
      src="https://cdn.botpenguin.com/assets/icons/help.png"></div> -->
  <!---->
  <div *ngIf="!helpCross" (click)="redirectToWhatsApp()" class="text"><img alt="" src="/assets/images/closecht.png">
    <span class="cross-icon" (click)="crossHelp()"><i class="fa fa-remove"></i></span>
    <p class="need-help">Need Help?</p>
  </div>

  <div *ngIf="helpCross" (click)="redirectToWhatsApp()" class="text w-help"><img alt="" src="/assets/images/closecht.png">
    <p class="need-help"><i class="fa fa-whatsapp" style="font-size: 23px;"></i></p>
  </div>
</div>