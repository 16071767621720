import {createAction, props} from '@ngrx/store';

export const loadDrivers = createAction(
  '[Driver] Load drivers',
  props<{ drivers: any[] }>()
);

export const addDriver = createAction(
  '[Driver] Add driver',
  props<{ driver: {} | any }>()
);

export const updateDriver = createAction(
  '[Driver] Update driver',
  props<{ driver: string | number, update: {} | any }>()
);

export const deleteDriver = createAction(
  '[Driver] Delete drivers',
  props<{ drivers: any[] }>()
);
