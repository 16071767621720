<div class="dropdown-container position-relative">
  <label for="material" *ngIf="showLabel">{{labelText}}</label>
  <div class="bp-dropdown-box position-relative p-0"
      (click)="enableToggle && (showDropDown = !showDropDown); $event.stopPropagation();">
      <div class="selected-option-box">
          <p>{{value}}</p>
      </div>
      <img class="drop-down-arrow" [ngClass]='{"drop-down-arrow-active": showDropDown }'
          src="/assets/version2/images/sidebar/arrow_drop_down_black.svg" alt="">
  </div>
  <ul *ngIf="showDropDown" class="select-box-dropdown">
      <div class="search-box-div  position-relative" *ngIf="enableSearch">
          <!-- <app-bp-input (inputChange)="searchEventHandler($event)" type="text" placeholder="Search" [showLabel]="false" height="50" [showErrorIcons]="false"></app-bp-input> -->
          <input type="text" (keyup)="searchEventHandler($event)" placeholder="Search">
      </div>
      <li (click)="optionSelectHandler(item, i)" class="options-box" *ngFor="let item of optionList; let i = index;">
          {{ item.title }}
      </li>
  </ul>
</div>