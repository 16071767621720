import { Action, createReducer, on } from '@ngrx/store';
import { loadDrivers, addDriver, updateDriver, deleteDriver } from './../actions/driver.action';


export const initialState: any[] = [];

const driverReducer = createReducer(initialState,
  on(loadDrivers, (state, { drivers }) => drivers),
  on(addDriver, (state, { driver }) => [driver, ...state]),
  on(deleteDriver, (state, { drivers }) => state.filter(item => !drivers.includes(item._id))),
  on(updateDriver, (state, { driver, update }) => state.map(item => {
    if (item._id === driver) {
      return { ...item, ...update };
    } else {
      return item;
    }
  }))
);

export function reducer(state: any[], action: Action) {
  return driverReducer(state, action);
}
