import { Action, createReducer, on } from '@ngrx/store';
import { loadTransactions } from './../../actions/payment/transaction.action';


export const initialState: any = [];

const transactionReducer = createReducer(initialState,
  on(loadTransactions, (state, { transactions }) => transactions)
);



export function reducer(state: any, action: Action) {
  return transactionReducer(state, action);
}
