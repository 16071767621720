<div class="table-container">
  <table class="rt-table">
    <thead class="table-header">
      <tr>
        <th scope="col" *ngIf="showCheckBox" class="checkbox-header text-center">
          <input (change)="checkboxHandler('', 'all')" type="checkbox" class="checkbox">
        </th>
        <ng-container *ngFor="let header of tableData.thead">
          <th>{{header.text}}</th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let body of tableData.tbody; let i = index;">
        <td *ngIf="showCheckBox" class="text-center">
          <input (change)="checkboxHandler(body._id, 'single')" [checked]="selectedElements.includes(body._id)" type="checkbox" class="checkbox">
        </td>
        <td *ngFor="let header of tableData.thead">
          <!--  display normal text -->
          <div  *ngIf="header.type === 'inputText' && header.isToolTip === true"  >{{ body[header.id] }}</div>
          <div *ngIf="header.type === 'inputText' && header.isToolTip == false">{{body[header.id]}}</div>
          <div *ngIf="header.type === 'toggle'" class="form-group position-relative">
            <label class="customtoggle">
              <input type="checkbox" class="checkbox" name="status" [checked]="body[header.id]" (change)="toggleActionHandler($event, header.id, i)"/>
              <span class="slidertogle round"> </span>
            </label>
          </div>
          
          <!-- display checbox -->
          <span class="checkbox text-center"  *ngIf="header.type==='checkbox'">
            <input type="checkbox" class="checkbox" [checked]="body[header.id]">
          </span>

          <!-- display actions -->

          <div  *ngIf="header.type==='actions'">
            <span class="material-icons action-icon" *ngFor="let action of header.actions; let j=index;" [ngClass]="action" (click)="actionEventHandler(action, i)">{{action}}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex flex-start" *ngIf="isPaginationVisible">
    <div class="m-auto p-2">
      <button class="btn btn-light text-dark" (click)="paginateUsers()">Load More</button>
    </div>
  </div>
</div>
