import {createAction, props} from '@ngrx/store';

export const loadOrders = createAction(
  '[Order] Load orders',
  props<{ orders: any[] }>()
);

export const singleUserOrders = createAction(
  '[Order] Single User orders',
  props<{ order: string }>()
);

export const updateOrder = createAction(
  '[Order] Update Order',
  props<{ order: number | string, update: {} | any }>()
);

export const removeAllOrders = createAction(
  '[Order] Remove All Order',
  props<any>()
);
