import { Action, createReducer, on } from '@ngrx/store';
import {
  loadProductCategory,
  updateProductCategory,
  deleteProductCategory,
  addProductCategory
} from '../../actions/inventory/product-category.action';
import ProductCategory from '../../shared/models/inventory/product-category';

export const catalogueFeatureKey = 'productcategory';

export const initialState: ProductCategory[] = [];

const productCategoryReducer = createReducer(
  initialState,
  on(loadProductCategory, (state, { productcategory }) => productcategory),
  on(addProductCategory, (state, { productcategory }) => [productcategory, ...state]),
  on(deleteProductCategory, (state, { productcategory }) => state.filter(item => !productcategory.includes(item._id))),
  on(updateProductCategory, (state, { productcategory, update }) => state.map(item => {
    if (item._id === productcategory) {
      return { ...item, ...update };
    } else {
      return item;
    }
  })),
);

export function reducer(state: ProductCategory[], action: Action) {
  return productCategoryReducer(state, action);
}
