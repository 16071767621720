import {createAction, props} from '@ngrx/store';

export const loadShops = createAction(
  '[Shop] Load shops',
  props<{ shops: any[] }>()
);

export const addShop = createAction(
  '[Shop] Add shop',
  props<{ shop: {} | any }>()
);

export const updateShop = createAction(
  '[Shop] Update shop',
  props<{ shop: string | number, update: {} | any }>()
);

export const deleteShop = createAction(
  '[Shop] Delete shops',
  props<{ shops: any[] }>()
);
