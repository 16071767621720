import { Action, createReducer, on } from '@ngrx/store';
import { loadUsers, updateUser, deleteUser } from './../actions/user.action';


export const initialState: any[] = [];

const userReducer = createReducer(initialState,
  on(loadUsers, (state, { users }) => users),
  on(deleteUser, (state, { user }) => state.filter(item => !user.includes(item._id))),
  on(updateUser, (state, { user, update }) => state.map(item => {
    if (item._id === user) {
      return { ...item, ...update };
    } else {
      return item;
    }
  }))
);

export function reducer(state: any[], action: Action) {
  return userReducer(state, action);
}
