import { Action, createReducer, on } from '@ngrx/store';
import { deletePromo, loadPromo, addPromo, updatePromo } from '../actions/promo.actions';


export const initialState: any[] = [];

const promoReducer = createReducer(initialState,
  on(loadPromo, (state, { promo }) => promo),
  on(addPromo, (state, { promo }) => [promo, ...state]),
  on(deletePromo, (state, { promo }) => state.filter(item => !promo.includes(item._id))),
  on(updatePromo, (state, { promo, update }) => state.map(item => {
    if (item._id === promo) {
      return { ...item, ...update };
    } else {
      return item;
    }
  }))
);

export function reducer(state: any[], action: Action) {
  return promoReducer(state, action);
}
