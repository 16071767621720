import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { reducer as userReducer } from './user.reducer';
import { reducer as shopReducer } from './shop.reducer';
import { reducer as driverReducer } from './driver.reducer';
import { reducer as configurationReducer } from './configuration.reducer';
import { reducer as profileReducer } from './profile.reducer';
import { reducer as permissionReducer } from './setting/permission.reducer';
import { reducer as catalogueReducer } from './inventory/catalogue.reducer';
import { reducer as addonReducer } from './inventory/addon.reducer';
import { reducer as productCategoryReducer } from './inventory/product-category.reducer';
import { reducer as locationReducer } from './inventory/location.reducer';
import { reducer as productReducer } from './inventory/product.reducer';
import { reducer as orderReducer } from './inventory/order.reducer';
import { reducer as supportReducer } from './support/support.reducer';
import { reducer as PromoReducer } from './promo.reducer';
import { reducer as transactionReducer } from './transaction/transaction.reducer';
import Catalogue from 'src/app/shared/models/inventory/catalogue';
import ProductCategory from '../shared/models/inventory/product-category';
export interface State {
  shops: any[];
  drivers: any[];
  configurations: any;
  users: any[];
  profile: any;
  catalogues: Catalogue[];
  productCategory: ProductCategory[];
  locations: any[];
  product: any[];
  permissions: any[];
  orders: any[];
  supports: any[];
  promo: any[];
  transactions: any[];
  addons: any[];
}

export const reducers: ActionReducerMap<State> = {
  shops: shopReducer,
  users: userReducer,
  drivers: driverReducer,
  configurations: configurationReducer,
  profile: profileReducer,
  catalogues: catalogueReducer,
  productCategory: productCategoryReducer,
  locations: locationReducer,
  product: productReducer,
  permissions: permissionReducer,
  orders: orderReducer,
  supports: supportReducer,
  promo: PromoReducer,
  transactions: transactionReducer,
  addons: addonReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
