import { CommonService } from './services/common/common.service';

import { AnalyticService } from './modules/analytics/service/analytic.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../environments/environment';
import { DashboardService } from './modules/dashboard/service/dashboard.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from './services/api/api.service';
import io from 'socket.io-client';
import { State, Store } from '@ngrx/store';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'tenant';
  adminObservable: any;
  socket: any;
  needHelp: any;
  helpCross = false;


  constructor(
    private apiServie: ApiService,
    private commonService: CommonService,
    private dashBoardService: DashboardService,
    private aService: AnalyticService,
    private toast: ToastrService,
    private store: Store<any>
  ) {
    this.store.select(state => state.configurations)
      .subscribe((configurations: any) => {
        if (configurations) {
          configurations = JSON.parse(JSON.stringify(configurations));
          if (configurations.legal?.contact) {
            this.needHelp = configurations.legal.contact.needHelp;
          }
        }
      });
  }

  ngOnInit(): void {
    if (!localStorage.getItem('refreshed')) {
      localStorage.clear();
      localStorage.setItem('refreshed', new Date().toString());
    }
    this.apiServie.getCatalogues();
    this.apiServie.getAddressFromIp();
    if (localStorage.getItem('@dashboardT0ken')) {

      // this.apiServie.getConfiguration();
      this.apiServie.getUsers();
      this.apiServie.getDrivers();
      this.apiServie.getShops();
      this.apiServie.getProfile();
      this.apiServie.getConfiguration();
      this.apiServie.getAddOnItemList();
      if (atob(localStorage.getItem('type')) === 'TENANT') {
        this.apiServie.updateLoginDeails();
      }

      try {
        this.socket = io(`${environment.TRACKING}?token=${localStorage.getItem('@dashboardT0ken')}`, {
          path: environment.TRACKING_PATH,
          // reconnection: true,
          // reconnectionDelay: 1000,
          // reconnectionDelayMax: 5000,
          // reconnectionAttempts: 5
        });
        this.socket.on('disconnect', (data) => {
          console.log('SOCKET DISCONNECTED');
        });
        this.socket.on('connect', (data) => {
          this.commonService.socketInstance.next(this.socket);
          console.log('SOCKET CONNECTED');
        });
        this.socket.on('error', (data) => {
          console.log('SOCKET ERROR', data);
        });

        // this.socket.on('new-order', order => {
        //   if (order) {
        //     this.notify();
        //     this.toast.success('You have a new order request');
        //     this.dashBoardService.newOrderSubscriber.next(order);
        //     this.apiServie.socketAcknowledgement(order._id)
        //   }
        // });

        this.socket.on('order-assigned', order => {
          if (order) {
            this.notify();
            this.toast.success('New order has been assigned to you by admin');
            this.dashBoardService.assignedOrderSubscriber.next(order);
            this.apiServie.socketAcknowledgement(order._id);
          }
        });

        this.socket.on('new-order-assigned', order => {
          if (order) {
            this.notify();
            this.toast.success('New order');
            this.dashBoardService.assignedOrderSubscriber.next(order);
            this.apiServie.socketAcknowledgement(order._id);
          }
        });

        this.socket.on('order-expired', order => {
          this.dashBoardService.orderExpiredSubscriber.next(order._id);
          this.toast.warning('Order request expired');
          this.apiServie.socketAcknowledgement(order._id);
        });

        this.socket.on('exported-data', (file: any) => {
          console.log("File Url : " + file);
          console.log("File Url : " + (environment.IMAGE_CDN + file));
          window.open(environment.IMAGE_CDN + file.url, '_blank');
          // this.aService.downloadReport(fileName)
          //   .then((report: any) => {
          //     this.toast.success('Report downloaded');
          //   })
          //   .catch((e: any) => {
          //     console.log(e);
          //     this.toast.warning(e.message);
          //   });
        });
      } catch (e) { console.log(e); }
    }
  }

  ngOnDestroy(): void {
    this.adminObservable.unsubscribe();
  }


  crossHelp() {
    this.helpCross = !this.helpCross;
  }

  notify() {
    const audio = new Audio();
    audio.src = '../assets/sounds/ping.mp3';
    audio.load();
    audio.play();
  }

  redirectToWhatsApp() {
    if (this.needHelp) {
      window.open(this.needHelp, '_blank');
    } else {
      window.open('https://wa.me/919740374298', '_blank');
    }
  }

}
