import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() labelText = 'Select';

  @Input() showLabel = true;

  @Input() value = 'Select';

  showDropDown = false;

  @Input() enableToggle = true;

  /**
   * Property to hold option values in object format
   * Title is key used to display the property in dropdown
   */
  @Input() optionList = [{ title: '1' }, { title: '2' }];

  /**
   * Property to emit selected option value to parent comp
   */
  @Output() clickEvent = new EventEmitter<any>();

  @Output() searchEvent = new EventEmitter<any>();

  @Input() enableSearch = false;

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.showDropDown = false;
  }

  constructor() { }

  ngOnInit(): void {
  }

  optionSelectHandler(item, index: number) {
    this.showDropDown = false;
    this.value  = item.title;
    this.clickEvent.emit({item, index});
  }

  searchEventHandler(event) {
    this.searchEvent.emit(event);
  }

}
