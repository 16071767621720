import {createAction, props} from '@ngrx/store';

export const loadProfile = createAction(
  '[Profile] Load profile',
  props<{ profile: any }>()
);

export const updateProfile = createAction(
  '[Profile] Update profile',
  props<{ profile: string | number, update: {} | any }>()
);
