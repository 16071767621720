import { Action, createReducer, on } from '@ngrx/store';
import { loadShops, addShop, updateShop, deleteShop } from './../actions/shop.action';


export const initialState: any[] = [];

const shopReducer = createReducer(initialState,
  on(loadShops, (state, { shops }) => shops),
  on(addShop, (state, { shop }) => [shop, ...state]),
  on(deleteShop, (state, { shops }) => state.filter(item => !shops.includes(item._id))),
  on(updateShop, (state, { shop, update }) => state.map(item => {
    if (item._id === shop) {
      return { ...item, ...update };
    } else {
      return item;
    }
  }))
);

export function reducer(state: any[], action: Action) {
  return shopReducer(state, action);
}
