import { Action, createReducer, on } from '@ngrx/store';
import { loadSupports, addSupport, updateSupport, deleteSupport } from '../../actions/support/support.action';


export const initialState: any[] = [];

const supportReducer = createReducer(initialState,
  on(loadSupports, (state, { supports }) => supports),
  on(addSupport, (state, { support }) => [...state, ...support]),
  on(deleteSupport, (state, { supports }) => supports.filter(item => !supports.includes(item._id))),
  on(updateSupport, (state, { support, update }) => state.map(item => {
    if (item._id === support) {
      return { ...item, ...update };
    } else {
      return item;
    }
  }))
);

export function reducer(state: any[], action: Action) {
  return supportReducer(state, action);
}
