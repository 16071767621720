import {createAction, props} from '@ngrx/store';

export const loadSupports = createAction(
  '[Support] Load supports',
  props<{ supports: any[] }>()
);

export const addSupport = createAction(
  '[Support] Add support',
  props<{ support: {} | any }>()
);

export const updateSupport = createAction(
  '[Support] Update support',
  props<{ support: string | number, update: {} | any }>()
);

export const deleteSupport = createAction(
  '[Support] Delete supports',
  props<{ supports: any[] }>()
);
