<div class="mb-4 mobile-flex">
  <input id="email" class="input-box " formControlName="username" placeholder="Email*" required
    type="email">

  <ng-container
    *ngIf="isLoginFormSubmitted && loginForm.get('username').invalid && (loginForm.get('username').dirty || loginForm.get('username').touched) else showEmptyEmailError">
    <small class="text-danger pl-1 form-error-message">
      Please fill a valid work email*</small>
  </ng-container>
  <ng-template #showEmptyEmailError>
    <small class="text-danger pl-1 form-error-message"
      *ngIf="isLoginFormSubmitted && !this.loginForm.get('username').value">
      Work email is required*</small>
  </ng-template>

</div>