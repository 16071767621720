export const environment = {
  production: false,
  BASE_URL: 'https://ecomm-api.relinns.website',
  UPLOAD: 'https://ecomm-api.relinns.website',
  TRACKING: 'https://ecomm-api.relinns.website/tracking',
  TRACKING_PATH: '/tracking',
  devMail: 'staging@relinns.me',
  GOOGLE_KEY: 'AIzaSyAK5zL6ok3vWgiiMVzWX2vQMhbmRGFJ3ng',
  bannedNames: ['app', 'help', 'admin', 'td', 'task', 'demo', 'product', 'shop', 'developer', 'test', 'tester', 'testor'],
  appName: 'AppsRhino E-comm',
  IP_TO_LOCATION: 'https://api.botpenguin.com/meta/ip',
  IMAGE_CDN: 'https://cdn.appsrhino.com/'
};
