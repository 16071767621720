import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { saveAs } from 'file-saver';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  snakBarConfig: any = {
    message: 'Hi This is message',
    autoHide: 2000,
    verticalPosition: 'top',
    horizontalPosition: 'right',
    actionButtonLabel: 'Retry'
  };
  action = false;

  constructor(
    public router: Router,
    private snackBar: MatSnackBar,
  ) { }

  httpRequest(url: string, body: any = null, method: string = 'GET', authenticated: boolean = true) {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) {
        this.openSnackBar({ message: 'Please connect to internet first !' });
        return reject;
      }
      fetch(environment.BASE_URL + url, {
        method,
        // signal,
        headers: {
          'x-appsrhino-package': (window as any).location.hostname,
          // 'x-appsrhino-package': 'app.appsrhino.com',
          'x-appsrhino-platform': 'web',
          'x-appsrhino-version': '1.0.0',
          'Content-Type': 'application/json',
          Authorization: authenticated
            ? `Bearer ${localStorage.getItem('@dashboardT0ken')}`
            : undefined
        },
        // credentials: 'include',
        body: body
          ? JSON.stringify(body)
          : undefined
      })
        .then(response => {
          if (response.status === 401 || response.status === 403) {
            if (authenticated) {
              this.openSnackBar({ message: 'Session Expired!' });
              localStorage.clear();
              return this.router.navigate(['/login']);
            } else {
              return response.json();
            }
          } else {
            return response.json();
          }
        })
        .then(res => {
          if (!res.status) {
            reject(res);
          } else {
            resolve(res);
          }
        })
        .catch(reject);
    });
  }

  openHttpRequest(url: string, body: any = null, method: string = 'GET', authenticated: boolean = true) {
    return new Promise((resolve, reject) => {

      if (!navigator.onLine) {
        this.openSnackBar({ message: 'Please connect to internet first !' });
        return reject;
      }
      fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          return response.json();
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  }

  httpFormRequest(url: string, body: any = null, method: string = 'POST', authenticated: boolean = true) {
    return new Promise((resolve, reject) => {

      if (!navigator.onLine) {
        this.openSnackBar({ message: 'Please connect to internet first !' });
        return reject;
      }

      fetch(environment.BASE_URL + url, {
        method,
        headers: {
          'x-appsrhino-package': (window as any).location.hostname,
          // 'x-appsrhino-package': 'app.appsrhino.com',
          'x-appsrhino-platform': 'web',
          'x-appsrhino-version': '1.0.0',
          Authorization: authenticated
            ? `Bearer ${localStorage.getItem('@dashboardT0ken')}`
            : undefined
        },
        body: body
          ? body
          : undefined
      })
        .then(response => response.json())
        .then(res => {
          if (!res.status) {
            reject(res);
          } else {
            resolve(res);
          }
        })
        .catch(reject);
    });
  }


  download(url: string, body: any = null, method: string = 'GET', authenticated: boolean = true, fileName = '') {
    return new Promise((resolve, reject) => {
      fetch(environment.BASE_URL + url, {
        method,
        headers: {
          'x-appsrhino-package': (window as any).location.hostname,
          // 'x-appsrhino-package': 'app.appsrhino.com',
          'x-appsrhino-platform': 'web',
          'x-appsrhino-version': '1.0.0',
          'Content-Type': 'application/json',
          Authorization: authenticated
            ? `Bearer ${localStorage.getItem('@dashboardT0ken')}`
            : undefined,
        },
        // credentials: 'include',
        body: body
          ? JSON.stringify(body)
          : undefined,
      })
        .then((response: Response) => {

          return response.blob();
        })
        .then(data => {
          saveAs(data, fileName || 'products.xlsx');
          resolve(fileName);
        })
        .catch(reject);
    });
  }

  openSnackBar(snackbar: any = null) {
    snackbar = { ...this.snakBarConfig, ...snackbar };
    const config = new MatSnackBarConfig();
    config.verticalPosition = snackbar.verticalPosition;
    config.horizontalPosition = snackbar.horizontalPosition;
    // config.duration = this.setAutoHide ? snackbar.autoHide : 0;
    config.duration = 5000;
    config.panelClass = ['snackBar-color']; // scss define in style.css
    this.snackBar.open(snackbar.message, this.action ? snackbar.actionButtonLabel : undefined, config);
  }

}
