<div style="display: flex; justify-content: center;">
  <nav class="navbar navbar-expand-sm mt-4">
    <ul class="navbar-nav">
      <li class="bp-navmenu-box">
        <span [routerLink]="['/bot-builder/whatsapp/settings/flow']" routerLinkActive="bp-tabs-border-color"
          class="bp-tabs-text">Location</span>
      </li>
      <li class="bp-navmenu-box">
        <span [routerLink]="['/bot-builder/whatsapp/settings/profile']" routerLinkActive="bp-tabs-border-color"
          class="bp-tabs-text">Catalogue</span>
      </li>
      <li class="bp-navmenu-box">
        <span [routerLink]="['/bot-builder/whatsapp/settings/broadcast']" routerLinkActive="bp-tabs-border-color"
          class="bp-tabs-text">Product Category</span>
      </li>
      <li class="bp-navmenu-box">
        <span [routerLink]="['/bot-builder/whatsapp/settings/drip-campaign']" routerLinkActive="bp-tabs-border-color"
          class="bp-tabs-text">Product</span>
      </li>
      <li class="bp-navmenu-box">
        <span [routerLink]="['/bot-builder/whatsapp/settings/widgets']" routerLinkActive="bp-tabs-border-color"
          class="bp-tabs-text">Merchants</span>
      </li>
      <li class="bp-navmenu-box">
        <span [routerLink]="['/bot-builder/whatsapp/settings/templates']" routerLinkActive="bp-tabs-border-color"
          class="bp-tabs-text">Delivery Person</span>
      </li>
      <li class="bp-navmenu-box">
        <span [routerLink]="['/bot-builder/whatsapp/settings/alerts']" routerLinkActive="bp-tabs-border-color"
          class="bp-tabs-text">Alerts</span>
      </li>
      <li class="bp-navmenu-box">
        <span [routerLink]="['/bot-builder/whatsapp/settings/wrapper']" routerLinkActive="bp-tabs-border-color"
          class="bp-tabs-text">API Key</span>
      </li>
    </ul>
  </nav>
</div>
<div style="display: flex; justify-content: space-around;">
  <div class="element">
    <app-input></app-input>
  </div>
  <div class="button-element">
    <app-button></app-button>
  </div>
</div>
<div style="display: flex; justify-content: space-around;">
  <div class="element">
    <app-dropdown></app-dropdown>
  </div>
  <div class="button-element">
    <app-table></app-table>
  </div>
</div>
<div style="display: flex; justify-content: space-around;">
  <div class="element">
    <button (click)="close=true">Delete</button>
    <app-modal *ngIf="close" (closeClick)="cancelPopup($event)"></app-modal>
  </div>
</div>