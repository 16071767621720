import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.scss']
})
export class ElementsComponent implements OnInit {

  close = false;

  constructor() { }

  ngOnInit(): void {
  }

  cancelPopup(event: any): void {
    this.close = !event;
  }

}
